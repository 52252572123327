exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-presence-js": () => import("./../../../src/pages/our-presence.js" /* webpackChunkName: "component---src-pages-our-presence-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-carbon-steel-strips-50-crv-4-sup-10-en-47-js": () => import("./../../../src/pages/products/carbon-steel-strips/50crv4-sup10-en47.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-50-crv-4-sup-10-en-47-js" */),
  "component---src-pages-products-carbon-steel-strips-c-10-sae-1010-js": () => import("./../../../src/pages/products/carbon-steel-strips/c10-sae-1010.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-10-sae-1010-js" */),
  "component---src-pages-products-carbon-steel-strips-c-20-sae-1020-js": () => import("./../../../src/pages/products/carbon-steel-strips/c20-sae-1020.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-20-sae-1020-js" */),
  "component---src-pages-products-carbon-steel-strips-c-40-en-8-js": () => import("./../../../src/pages/products/carbon-steel-strips/c40-en8.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-40-en-8-js" */),
  "component---src-pages-products-carbon-steel-strips-c-45-s-45-c-js": () => import("./../../../src/pages/products/carbon-steel-strips/c45-s45c.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-45-s-45-c-js" */),
  "component---src-pages-products-carbon-steel-strips-c-50-js": () => import("./../../../src/pages/products/carbon-steel-strips/c50.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-50-js" */),
  "component---src-pages-products-carbon-steel-strips-c-55-en-9-js": () => import("./../../../src/pages/products/carbon-steel-strips/c55-en9.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-55-en-9-js" */),
  "component---src-pages-products-carbon-steel-strips-c-60-js": () => import("./../../../src/pages/products/carbon-steel-strips/c60.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-60-js" */),
  "component---src-pages-products-carbon-steel-strips-c-62-js": () => import("./../../../src/pages/products/carbon-steel-strips/c62.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-62-js" */),
  "component---src-pages-products-carbon-steel-strips-c-65-js": () => import("./../../../src/pages/products/carbon-steel-strips/c65.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-65-js" */),
  "component---src-pages-products-carbon-steel-strips-c-70-js": () => import("./../../../src/pages/products/carbon-steel-strips/c70.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-70-js" */),
  "component---src-pages-products-carbon-steel-strips-c-75-js": () => import("./../../../src/pages/products/carbon-steel-strips/c75.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-75-js" */),
  "component---src-pages-products-carbon-steel-strips-c-80-en-42-j-js": () => import("./../../../src/pages/products/carbon-steel-strips/c80-en42j.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-80-en-42-j-js" */),
  "component---src-pages-products-carbon-steel-strips-c-95-js": () => import("./../../../src/pages/products/carbon-steel-strips/c95.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-95-js" */),
  "component---src-pages-products-carbon-steel-strips-c-98-en-44-d-js": () => import("./../../../src/pages/products/carbon-steel-strips/c98-en44d.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-c-98-en-44-d-js" */),
  "component---src-pages-products-carbon-steel-strips-index-js": () => import("./../../../src/pages/products/carbon-steel-strips/index.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-index-js" */),
  "component---src-pages-products-carbon-steel-strips-sk-4-js": () => import("./../../../src/pages/products/carbon-steel-strips/sk4.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-sk-4-js" */),
  "component---src-pages-products-carbon-steel-strips-sk-5-sk-85-js": () => import("./../../../src/pages/products/carbon-steel-strips/sk5-sk85.js" /* webpackChunkName: "component---src-pages-products-carbon-steel-strips-sk-5-sk-85-js" */),
  "component---src-pages-products-cold-rolled-close-annealed-crca-d-js": () => import("./../../../src/pages/products/cold-rolled-close-annealed-crca/d.js" /* webpackChunkName: "component---src-pages-products-cold-rolled-close-annealed-crca-d-js" */),
  "component---src-pages-products-cold-rolled-close-annealed-crca-dd-js": () => import("./../../../src/pages/products/cold-rolled-close-annealed-crca/dd.js" /* webpackChunkName: "component---src-pages-products-cold-rolled-close-annealed-crca-dd-js" */),
  "component---src-pages-products-cold-rolled-close-annealed-crca-edd-js": () => import("./../../../src/pages/products/cold-rolled-close-annealed-crca/edd.js" /* webpackChunkName: "component---src-pages-products-cold-rolled-close-annealed-crca-edd-js" */),
  "component---src-pages-products-cold-rolled-close-annealed-crca-if-js": () => import("./../../../src/pages/products/cold-rolled-close-annealed-crca/if.js" /* webpackChunkName: "component---src-pages-products-cold-rolled-close-annealed-crca-if-js" */),
  "component---src-pages-products-cold-rolled-close-annealed-crca-index-js": () => import("./../../../src/pages/products/cold-rolled-close-annealed-crca/index.js" /* webpackChunkName: "component---src-pages-products-cold-rolled-close-annealed-crca-index-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-50-crv-4-sup-10-en-47-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/50crv4-sup10-en47.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-50-crv-4-sup-10-en-47-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-c-55-en-9-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/c55-en9.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-c-55-en-9-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-c-62-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/c62.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-c-62-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-c-80-en-42-j-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/c80-en42j.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-c-80-en-42-j-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-c-98-en-44-d-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/c98-en44d.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-c-98-en-44-d-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-index-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/index.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-index-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-sk-4-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/sk4.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-sk-4-js" */),
  "component---src-pages-products-hardened-tempered-steel-strips-sk-5-sk-85-js": () => import("./../../../src/pages/products/hardened-tempered-steel-strips/sk5-sk85.js" /* webpackChunkName: "component---src-pages-products-hardened-tempered-steel-strips-sk-5-sk-85-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */)
}

